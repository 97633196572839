import { LimpaCache } from "../finalizacao/limparcache";
import { GravaLocal } from "../../conexao/localstorage";
import {
  DescricaoMinimoPedido,
  MensalidadePaga,
  ValidaAbertura,
  ValidaStatusLoja,
} from "../util/validaAbertura";
import { PrimeiraLetraMaiuscula } from "../util/primeiraLetraMaiuscula";
import { useParams, Link } from "react-router-dom";
import Modal from "../modal/modal";
import { API, ExpressAPI } from "../../conexao/api";
import { useState, useEffect } from "react";
import "./cabecalho.css";
import { ImagemTopo } from "../home/principal/imagem";
import { VerMais } from "../home/vermais/vermais";
import { BuscaLocal } from "../../conexao/localstorageBusca";
import { Rating } from "primereact/rating";
import ReactStars from "react-rating-stars-component";
import ReactPixel from "react-facebook-pixel";
import { PIXELFACEBOOK } from "../util/datahora";
import { FidelidadeEmpresa } from "../fidelidade/empresa/fidelidadeEmpresa";
import { CupomDescontoViwer } from "../cupom";

var inicio = false;
export function Cabecalho(props) {
  const { empresa } = useParams();
  var empresaLogada = process.env.REACT_APP_EMPRESA || empresa;
  if (empresaLogada === undefined) {
    //voltar para o inicio
    //Fazer uma pagina com as lojas
    empresaLogada = "demo";
  }
  let Fidelidade = false;
  try {
    Fidelidade = props.fidelidade;
  } catch (error) {}

  GravaLocal("NOME_LINK_EMPRESA", empresaLogada);
  const [carregou, setCarregou] = useState(inicio);
  const [NomeEstabelecimento, setNomeEstabelecimento] = useState("");
  const [UrlLogo, setUrlLogo] = useState("");
  const [UrlHeader, setUrlHeader] = useState("");
  const [UserId, setUserId] = useState(0);
  const [Mesa_Descricao, setMesa_Descricao] = useState("");
  const [Mesa_Numero, setMesa_Numero] = useState("");
  const [Avalicao, setAvalicao] = useState(5);
  const [Votos, setVotos] = useState(0);

  const [Status, setStatus] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [Mesa, setMesa] = useState();
  const [CupomPrimeiraCompra, setCupomPrimeiraCompra] = useState([]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const link =
    "generica.php?tabela=ws_empresa&where=nome_empresa_link='" +
    empresaLogada +
    "'";

  const linkCupomPrimeira = `generica.php?tabela=cupom_desconto&where=primeira = 1 and data_validade >= curdate() and user_id = ${UserId} and total_vezes > 0`;

  const ConsultaStatusLoja = async () => {
    try {
      const response = await API.get(link);
      GravaLocal("dados_empresa", JSON.stringify(response.data));

      let statusLoja = await ValidaStatusLoja();
      setStatus(statusLoja);
    } catch (error) {
      //console.error("Erro ao consultar status da loja:", error);
    }
  };

  useEffect(() => {
    GravaLocal("categoria_selecionada", 0);
    GravaLocal("produto_selecionada", 0);
    GravaLocal("delivery_vembuscar", 0);

    if (BuscaLocal("MESA_DESCRICAO") != null) {
      setMesa_Descricao(BuscaLocal("MESA_DESCRICAO"));
      setMesa_Numero(BuscaLocal("MESA_NUMERO"));
    }

    //https://site-api-v2.goopedir.com/api/empresa/catalogy/demo

    ExpressAPI.get(`/api/empresa/catalogy/${empresaLogada}`).then(function (
      response
    ) {
      GravaLocal("new_empresa", JSON.stringify(response.data));
      console.log(response.data);
    });

    API.get(link).then(function (response) {
      // //////////////console.log(response.data)
      if (response.data == null) {
        window.location.replace("/");
      }
      API.get("media_restaurante.php?user=" + response.data[0]["user_id"]).then(
        function (response) {
          setAvalicao(response.data[0]["media"]);
          setVotos(response.data[0]["votos"]);
        }
      );

      GravaLocal("dados_empresa", JSON.stringify(response.data));
      setNomeEstabelecimento(
        PrimeiraLetraMaiuscula(response.data[0]["nome_empresa"])
      );
      // setUrlLogo("https://goopedir.com/uploads/" + response.data[0]['img_logo'])
      setUrlLogo(
        "https://fotos.goopedir.com/fotos/empresa/" + btoa(response.data[0][1])
      );
      // setUrlHeader("https://goopedir.com/uploads/" + response.data[0]['img_header'])
      setUserId(response.data[0][1]);
      setStatus(ValidaStatusLoja);
      const linkTag = document.querySelector('link[rel="icon"]');
      linkTag.href =
        "https://fotos.goopedir.com/fotos/empresa/" + btoa(response.data[0][1]);
      const linkApple = document.querySelector('link[rel="apple-touch-icon"]');
      linkApple.href =
        "https://fotos.goopedir.com/fotos/empresa/" + btoa(response.data[0][1]);
      document.title = PrimeiraLetraMaiuscula(response.data[0]["nome_empresa"]);
      props.onLoad(
        response.data[0]["user_id"],
        response.data[0]["fidelidade_status"]
      );
      inicio = true;
      setCarregou(inicio);
      ConsultaStatusLoja();
      setInterval(async function () {
        await ConsultaStatusLoja();
      }, 15 * 1000);

      const novaUrlImagem =
        "https://fotos.goopedir.com/fotos/empresa/" + btoa(response.data[0][1]);
      const metaTagImagem = document.querySelector('meta[property="og:image"]');

      if (metaTagImagem) {
        metaTagImagem.setAttribute("content", novaUrlImagem);
      }
    });
  }, []);

  useEffect(() => {
    if (UserId > 0) {
      API.get(linkCupomPrimeira).then(function (response) {
        setCupomPrimeiraCompra(response.data);
      });
    }
  }, [UserId]);

  const VoltaHome = () => {
    window.location = "/" + empresaLogada;
  };

  return (
    <>
      {/* {linkCupomPrimeira} */}
      {carregou && (
        <>
          {/* {process.env.REACT_APP_EMPRESA == undefined ? <ImagemTopo imagem={UrlHeader} /> : null} */}

          {showModal && (
            <Modal onClose={handleCloseModal} cor="Black">
              <VerMais />
            </Modal>
          )}

          <header className="merchant-info" style={{ overflow: "auto" }}>
            <div className="merchant-info__content-container">
              {/* <Link to={'/fidelidade'}> */}
              <img
                className="merchant-info__logo"
                src={UrlLogo}
                onClick={VoltaHome}
              />
              {/* </Link> */}
              <div className="merchant-info__content">
                <div className="merchant-info__title-container">
                  <h1 className="merchant-info__title">
                    {NomeEstabelecimento}
                  </h1>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "-7px",
                      flexDirection: "column",
                    }}
                  >
                    {/* <Rating value={Avalicao}  cancel={false} style={{gap:'0.1rem',marginLeft: '10px', marginRight: '5px'}}/>  */}
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ReactStars
                        count={5}
                        value={Avalicao}
                        size={26}
                        isHalf={true}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#ffd700"
                      />
                      <p style={{ fontSize: "1rem", marginLeft: "5px" }}>
                        ({Number(Avalicao).toFixed(2).replace(".", ",")})
                      </p>
                    </div>
                    <p
                      style={{
                        fontSize: "0.7rem",
                        color: "#ccc",
                        marginTop: "-5px",
                        marginLeft: "3px",
                      }}
                    >
                      {" "}
                      {Votos} Avaliações
                    </p>
                  </div>
                </div>
                {Mesa_Descricao == "" ? (
                  <>
                    <div className="merchant-info__detail-container">
                      <button
                        className="merchant-details__button"
                        onClick={handleOpenModal}
                      >
                        Detalhes
                      </button>
                      <div className="merchant-info__minimum-order">
                        {DescricaoMinimoPedido()}
                      </div>
                    </div>
                    {Status ? (
                      <button
                        className="merchant-details__button"
                        style={{ color: "green" }}
                      >
                        Aberto ❤️‍🔥
                      </button>
                    ) : (
                      <button className="merchant-details__button">
                        Fechado ❌🚫
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    <div className="merchant-info__detail-container">
                      <button className="merchant-details__button">
                        {Mesa_Descricao + " " + Mesa_Numero}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            {CupomPrimeiraCompra ? (
              <CupomDescontoViwer dados={CupomPrimeiraCompra[0]} />
            ) : null}

            {Fidelidade ? <FidelidadeEmpresa /> : null}
          </header>
          {props.children}
        </>
      )}
    </>
  );
}
